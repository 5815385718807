import * as React from 'react'
import { useTheme } from '@emotion/react'

const textStyles = ({ colors }) => ({
  color: colors.text,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '1.5rem',
  lineHeight: '2.4rem',
  letterSpacing: '-0.02em',
  textAlign: 'center'
})

const Text = (props) => {
  const theme = useTheme()

  return (
    <p style={textStyles(theme)} {...props}>
      {props.children}
    </p>
  )
}

export default Text
