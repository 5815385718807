import React, { useState } from 'react'
import { Widget } from '@typeform/embed-react'
import facepaint from 'facepaint'
import { isAndroid, isIOS } from 'react-device-detect'

import Layout from '../../components/layouts/landing'
import GradientButton from '../../components/GradientButton'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import IconImage from '../../images/icon.svg'

const mq = facepaint([
  '@media(min-width: 600px)'
])

const wrapperStyles = mq({
  maxWidth: ['auto', 520]
})

const IndexPage = ({ id, location }) => {
  const [formOpen, setFormOpen] = useState(false)
  let firstName = null

  if (location?.search) {
    const params = new URLSearchParams(location.search)
    const paramVal = params.get('n')

    if (paramVal) {
      try {
        firstName = atob(paramVal)
      } catch { }
    }
  }

  return (
    <Layout title="wrkvbs">
      <div css={{ width: '100%', textAlign: 'center' }}>
        <Icon css={{ margin: '0 auto' }}>
          <IconImage />
        </Icon>
      </div>
      {
        !firstName && <Heading css={{ marginTop: 35 }}>
          Invalid request.
        </Heading>
      }
      {firstName && !formOpen &&
        <div css={wrapperStyles}>
          <Heading css={{ marginTop: 35 }}>
            {!isIOS && `Thanks for trying to help ${firstName} complete their profile on WRKVBS.`}
            {isIOS && `Help ${firstName} complete their profile on WRKVBS`}
          </Heading>
          <Text>
            {isIOS && 'Download the app below'}
            {isAndroid && 'Unfortunately we don\'t currently have an Android app. Give us your contact information and we\'ll let you know as soon as we do.'}
            {!isIOS && !isAndroid && 'We\'re not yet supporting recording on desktop, so please open the link you received on your phone to download our iOS app, or enter your information below if you are an Android user.'}
          </Text>

          {isIOS
            ? <GradientButton css={{ marginTop: 20 }} href="https://testflight.apple.com/join/WuOPIal4">
              Download the App
            </GradientButton>
            : <GradientButton css={{ marginTop: 20 }} onClick={() => setFormOpen(true)}>
              Sounds Good
            </GradientButton>
          }
        </div>
      }

      <div css={{ width: '100%', display: formOpen ? 'block' : 'none' }}>
        <Widget id="IkMAv7FU" height="540" hideHeaders={true} hideFooter={true} opacity="0" />
      </div>
    </Layout>
  )
}

export default IndexPage
