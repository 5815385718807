import * as React from 'react'
import { Global } from '@emotion/react'
import facepaint from 'facepaint'

const mq = facepaint([
  '@media(min-width: 800px)',
  '@media(min-width: 1120px)'
])

const globalStyles = {
  'html, body, #___gatsby, #gatsby-focus-wrapper': {
    height: '100%'
  }
}

const pageStyles = mq({
  display: 'flex',
  width: '100%',
  height: '100%'
})

const containerStyles = mq({
  display: 'flex',
  margin: 'auto',

  justifyContent: 'center',
  flexWrap: 'wrap',
  maxWidth: ['100%', 'auto', 'auto'],
  minWidth: ['100%', 'auto', 'auto'],
  padding: [32, 0, 0]

})

const Layout = ({ pageTitle, children }) => {
  return (
    <>
      <Global styles={globalStyles}
      />
        <title>{pageTitle}</title>
        <main css={pageStyles}>
          <div css={containerStyles}>
            {children}
          </div>
        </main>
    </>
  )
}
export default Layout
